<template>
  <div class="footer">
    <div class="flex items-center justify-between top">
      <div class="left">
        <p>{{ $t("语言") }}</p>
        <el-dropdown trigger="click" @command="handleCommand" placement="">
          <div class="flex items-center justify-between list">
            <div class="language">{{ currentLan }}</div>
            <div class="select">
              <img src="@/assets/imgs/select.png" alt="" />
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(lang, index) in langs"
              :key="index"
              :command="lang"
              :class="curLanguage == lang.value ? 'languageActive' : ''"
              >{{ lang.text }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="flex items-center justify-between list">
          <div class="language">11111</div>
          <div class="select" @click="isShow = !isShow">
            <img src="@/assets/imgs/select.png" alt="" />
          </div>
          <div class="listBox" v-if="isShow">
            li
          </div>
        </div> -->
      </div>
      <div class="right">
        <li>
          <p class="miniTitle">{{ $t("支付产品") }}</p>
          <p class="text">{{ $t("线下支付") }}</p>
        </li>
        <li>
          <p class="miniTitle">{{ $t("USDT支持") }}</p>
          <p class="text">{{ $t("收费标准") }}</p>
        </li>
        <li>
          <p class="miniTitle">{{ $t("网上支付") }}</p>
          <p class="text">{{ $t("联系我们") }}</p>
        </li>
        <li>
          <p class="miniTitle">{{ $t("网上支付") }}</p>
          <p class="text">{{ $t("常见问题") }}</p>
        </li>
      </div>
    </div>
    <div class="flex items-center justify-between bottom">
      <div class="left">Copyright @2024 Smart Pay</div>
      <div class="flex items-center justify-center right">
        <p>{{ $t("网站使用条款") }}</p>
        <p>{{ $t("Cookie声明") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footerPage",
  data() {
    return {
      isShow: false,
      langs: [
        { text: "English", value: "en" },
        { text: "中文", value: "zh" },
        { text: "日本语", value: "jp" },
      ],
      curLanguage: localStorage.getItem("accept_language"),
      currentLan: "",
    };
  },
  methods: {
    handleCommand(command) {
      localStorage.setItem("accept_language", command.value);
      this.$i18n.locale = command.value;
      this.currentLan = this.langs.find(
        (item) => item.value === command.value
      )?.text;
      window.location.reload();
    },
  },
  mounted() {
    this.currentLan = this.langs.find(
      (item) => item.value === this.curLanguage
    )?.text;
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 203px;
  color: #fff;
  background: #212020;
  .top {
    padding: 40px 120px 29px;
    width: 100%;
    min-height: 131px;
    border-bottom: 1.49px solid #2b2b2b;
    .left {
      .list {
        padding: 12px 17px 11.32px;
        margin-top: 7px;
        width: 180px;
        height: 37.32px;
        border-radius: 74.64px;
        border: 0.75px solid #2a2a2a;
        .language {
          font-family: "MiSans-Demibold";
          font-size: 12px;
          font-weight: 600;
          line-height: 13.86px;
          text-align: left;
        }
      }
      p {
        font-family: "MiSans-Demibold";
        font-size: 12px;
        font-weight: 600;
        line-height: 28.36px;
        text-align: left;
        color: #fff;
        cursor: pointer;
      }
    }
    .right {
      li {
        list-style-type: none;
        display: inline-block;
        margin-right: 160px;
        .miniTitle {
          font-family: "MiSans-Demibold";
          font-size: 14px;
          font-weight: 600;
          line-height: 28.36px;
          text-align: left;
          color: #fff;
          cursor: pointer;
        }
        .text {
          font-family: "MiSans-Demibold";
          font-size: 12px;
          font-weight: 600;
          line-height: 28.36px;
          text-align: left;
          color: #8c8c8c;
          cursor: pointer;
          //   margin-top: 40px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .bottom {
    padding: 30px 120px 29px;
    font-family: "MiSans-Demibold";
    font-size: 12px;
    font-weight: 600;
    line-height: 13.26px;
    text-align: left;
    color: #fff;
    opacity: 0.3;
    p {
      cursor: pointer;
      &:first-child {
        margin-right: 48.75px;
      }
    }
  }
}
</style>

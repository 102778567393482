<template>
  <!-- <WrapBox> -->
  <div class="eightPage" ref="qudao" v-show="qudaoShow">
    <div class="title demibold animate__animated animate__fadeInUp">
      {{ $t("申请步骤介绍") }}
    </div>
    <div class="smallTitle regular animate__animated animate__zoomInDown">
      <span class="animate__animated animate__zoomInDown">
        {{
          $t("您仅需享受最快10分钟申请 立即拥有输你的自己的SmartCard")
        }} </span
      ><br />
      <span class="animate__animated animate__zoomInDown">
        {{ $t("申请前务必核对申请条件及基础信息!") }}
      </span>
    </div>
    <div class="content">
      <div class="bg"></div>
      <div
        class="colorBg animate__animated animate__zoomInRight animate__delay-1s"
      >
        <div class="text">
          <p class="first">SMART PAY</p>
          <p class="two">SMART PAY</p>
          <p class="three">SMART PAY</p>
          <p class="four">SMART PAY</p>
        </div>
        <div class="imgs">
          <img
            src="@/assets/imgs/step1.png"
            alt=""
            class="box animate__animated animate__zoomInRight animate__delay-2s animate__faster"
          />
          <img
            src="@/assets/imgs/step2.png"
            alt=""
            class="box animate__animated animate__zoomInRight animate__delay-3s animate__faster"
          />
          <img
            src="@/assets/imgs/step3.png"
            alt=""
            class="box animate__animated animate__zoomInRight animate__delay-4s animate__faster"
          />
        </div>
      </div>
    </div>
    <div class="content1">
      <div
        class="stepText1 animate__animated animate__zoomInRight animate__delay-2s animate__faster"
      >
        {{ $t("在线会员申请，注册1分钟") }}<br />
        {{ $t("完成会员申请后开，开卡填写资料最长5") }}<br />{{ $t("分钟") }}
      </div>
      <div
        class="stepText2 animate__animated animate__zoomInRight animate__delay-3s animate__faster"
      >
        {{ $t("将USDT转入钱包，增加自己的数字资产") }}<br />{{
          $t("（请根据需求，量力而行）")
        }}
      </div>
      <div
        class="stepText3 animate__animated animate__zoomInRight animate__delay-4s animate__faster"
      >
        {{ $t("申请开卡，选择您希望的品牌卡片（visa") }}<br />{{
          $t("或master），将USDT转入卡内转变为消")
        }}<br />{{ $t("费额度（此操作可重复进行，可逆向操") }}<br />{{
          $t("作，U转卡2%-3%费用，卡转回U免费）")
        }}
      </div>
    </div>
    <el-button type="primary" class="createCard" @click="toLogin">{{
      $t("立即开卡")
    }}</el-button>
  </div>
  <!-- </WrapBox> -->
</template>
<script>
// import WrapBox from "./wrap/wrapBox.vue";
export default {
  name: "eightPage",
  //   components: { WrapBox },
  data() {
    return {
      qudaoShow: false,
      isAnimating: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    toLogin() {
      window.open("https://merchant.insmartpay.com/login");
    },
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 20, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var b, c, a, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        // e = dom.offsetHeight; // 元素高度
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
        // if (b + c > a + e) {
        //   this.qudaoShow = false;
        // }
        // if (b + c < a) {
        //   this.qudaoShow = false;
        // }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.eightPage {
  height: 100%;
  background-color: #131313;
  color: #fff;
  padding: 100px 0;
  .title {
    font-size: 44px;
    font-weight: 700;
    line-height: 58.34px;
    text-align: center;
    margin-bottom: 50px;
  }
  .smallTitle {
    font-size: 22px;
    font-weight: 400;
    line-height: 29.17px;
    text-align: center;
    color: #fff;
    opacity: 0.8;
  }
  .content {
    width: 100%;
    height: 200px;
    margin-top: 84px;
    position: relative;
    margin-bottom: 50px;
    .bg {
      position: absolute;
      left: 0;
      width: 100%;
      height: 169px;
      background-color: #353535;
      animation: move 2s forwards;
    }
    .colorBg {
      position: absolute;
      left: 0;
      width: 100%;
      height: 169px;
      opacity: 0.8px;
      background: linear-gradient(181deg, #846d37 8.32%, #624d21 96.16%);
      img {
        display: inline-block;
        width: 301px;
        height: 219px;
        position: absolute;
        z-index: 111;
        &:nth-child(1) {
          left: 10%;
          top: -50px;
        }
        &:nth-child(2) {
          left: 50%;
          transform: translateX(-50%);
          top: -50px;
        }
        &:nth-child(3) {
          right: 10%;
          top: -50px;
        }
      }
      .text {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 99;
        p {
          font-family: "MiSans-Demibold";
          font-size: 40px;
          font-weight: 600;
          line-height: 53.04px;
          text-align: left;
          display: inline-block;
          width: 241px;
          height: 53px;
          opacity: 0.3;
        }
        .first {
          position: absolute;
          left: 8%;
          top: 50%;
          transform: translateY(-50%);
        }
        .two {
          position: absolute;
          left: 31%;
          top: 50%;
          transform: translateY(-50%);
        }
        .three {
          position: absolute;
          left: 52%;
          top: 50%;
          transform: translateY(-50%);
        }
        .four {
          position: absolute;
          right: 8%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .content1 {
    width: 100%;
    min-height: 74px;
    // margin-top: 50px;
    color: #fff;
    font-family: "MiSans-regular";
    font-size: 18px;
    font-weight: 400;
    line-height: 23.87px;
    text-align: center;
    position: relative;
    .stepText1 {
      width: 22%;
      position: absolute;
      left: 10%;
      top: 0;
    }
    .stepText2 {
      width: 22%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
    }
    .stepText3 {
      width: 22%;
      position: absolute;
      right: 10%;
      top: 0;
    }
  }
  .createCard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 88px auto 0;
    color: #fff;
    width: 401px;
    height: 80px;
    border-radius: 60px;
    background: linear-gradient(181deg, #846d37 8.32%, #624d21 96.16%);
    font-family: "MiSans-Demibold";
    font-size: 24px;
    font-weight: 600;
    line-height: 31.82px;
  }
}
@keyframes move {
  0% {
    opacity: 0;
    transform: translate(100%, 100%) rotate(45deg); /* 从右下角进入 */
  }
  100% {
    opacity: 1;
    transform: rotate(3.25deg); /* 向左旋转 3.25 度 */
  }
}
</style>
